<template>
    <div>
        <div class="row mt-2" style="background-color:white;padding:10px">
           
            <div class="col-md-8">
                 <h5>Organization Details</h5>
                <table class="table table-sm ">
                    <tr>
                        <th>Name</th>
                        <td>{{ orgDetails.name }}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{{ orgDetails.description }}</td>
                    </tr>
                    <tr>
                        <th>Org Code</th>
                        <td>{{ orgDetails.code }}</td>
                    </tr>
                    <tr>
                        <th>Registration Number</th>
                        <td>{{ orgDetails.registration_number}}</td>
                    </tr>
                    <tr>
                        <th>GST</th>
                        <td>{{ orgDetails.gst }}</td>
                    </tr>
                    <tr>
                        <th>PAN</th>
                        <td>{{ orgDetails.pan}}</td>
                    </tr>
                    <tr>
                        <th>CIN</th>
                        <td>{{ orgDetails.cin}}</td>
                    </tr>
                    <tr>
                        <th>Registration Number</th>
                        <td>{{ orgDetails.registration_number}}</td>
                    </tr>
                    <tr>
                        <th>Registration Date</th>
                        <td>{{ orgDetails.registration_date | moment("ddd,Do MMM  YYYY") }}</td>
                    </tr>
            </table>
            </div>
            <div class="col-md-4 text-right">
                <img :src="orgDetails.logo" class="img-fluid img-thumbnail" alt="">
            </div>
            
            
        </div>

        <div class="row mt-2" style="background-color:white;padding:10px">
           
            <div class="col-md-6 mb-2">
                <h5>Oganization Holidays</h5>
            </div>
            <div class="col-md-6 text-right  mb-2">
                <b-button v-b-modal.modal-1 variant="primary">Create New Holiday</b-button>
                <b-modal id="modal-1" title="Create New Holiday" hide-footer>
                        <b-form @submit.prevent="onSubmit">
                        

                        <b-form-group id="input-group-2" label="Name:" label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.name"
                            placeholder="Enter Holiday name"
                            required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="example-datepicker1" label="Date:" label-for="example-datepicker5">
                            <b-form-datepicker id="example-datepicker" v-model="form.date" class="mb-2"></b-form-datepicker>
                            
                        </b-form-group>
                        <b-button type="submit" variant="primary">Submit</b-button>
                        </b-form>
                </b-modal>
            </div>
        
           
            <div class="col-md-12">
               <table class="table table-sm">
                   <thead>
                       <tr>
                           <th>#</th>
                           <th>Name</th>
                           <th>Date</th>
                       </tr>
                   </thead>
                   <tbody>
                       <template v-if="holidays.length > 0">
                            <tr v-for="(holiday,i) in holidays" :key="i">
                                <td>{{ 1 + i }}</td>
                                <td>{{ holiday.name }}</td>
                                <td>{{ holiday.date   | moment("DD-MM-YYYY") }}</td>
                            </tr>
                       </template>
                       <template v-else>
                            <tr>
                                <td colspan="3" class="text-center">No Record Found</td>
                                
                            </tr>
                       </template>
                      
                   </tbody>
               </table>
            </div>
            
            
        </div>

    </div>
</template>
<script>
export default {
    data(){
        return {
            orgDetails: false,
            holidays: [],
            form:{
                name: '',
                date:''
            }
        }
    },
    mounted(){
        this.getOrgDetails();
        this.getOrgHoliday();
    },
    methods:{
        onSubmit(){
             window.axios.post("holiday-calender",this.form)
              .then((resp) => {
                console.log(resp);
                if(resp.data.success){
                    this.form.name = ''; this.form.date = '';
                    alert(resp.data.message);
                    this.getOrgHoliday();
                }
               
            });
        },
        getOrgHoliday(){
             window.axios.get("holiday-calender")
              .then((resp) => {
                console.log(resp);
                this.holidays= resp.data.data;
            });
        },
        getOrgDetails(){
            window.axios.get("organization")
            .then((resp) => {
                console.log(resp);
                this.orgDetails= resp.data.data;
            });
        }
    }
}
</script>
